<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      Detalhes
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="data.subject"
        label="Assunto"
        readonly
        outlined
        dense
      />

      <v-textarea
        v-model="data.text"
        label="Descrição"
        auto-grow
        readonly
        outlined
        dense
      />

      <div class="chips-container">
        <span
          class="container-chips-text"
        >
          Anexos
        </span>

        <div
          class="chips-container-content"
          :style="{'justify-content': isLoadingGetFile ? 'center': 'flex-start'}"
        >
          <v-progress-circular
            v-show="isLoadingGetFile"
            color="warning"
            indeterminate
          />

          <v-chip
            v-for="file in files"
            v-show="computedViewFileContainer"
            :key="file.id"
            size="small"
            color="warning"
            class="chips-content"
            outlined
            dense
            @click="downloadFile(file.id, file.name)"
          >
            <span v-if="file.id === isLoadingFile ? false : true">{{ file.name }}</span>

            <v-progress-circular
              v-else
              :key="file.id"
              :size="25"
              color="white"
              indeterminate
            />
          </v-chip>

          <h4
            v-show="!computedViewFileContainer && !isLoadingGetFile"
            class="font-100 mt-2"
          >
            - Nenhum anexo
          </h4>
        </div>
      </div>

      <v-switch
        v-model="computedStatus"
        class="ml-2"
        color="success"
        label="Marcar como visto"
        :loading="isLoadingSwitch"
        inset
      />
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { saveAs } from 'file-saver'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      files: [],

      isViewingFile: false,
      isLoadingFile: false,
      isLoadingGetFile: false,
      isLoadingSwitch: false,
    }
  },

  computed: {
    computedViewFileContainer() {
      const isFileLoading = !this.isLoadingGetFile
      const isFileViewing = this.isViewingFile

      return isFileLoading && isFileViewing
    },

    computedStatus: {
      get() {
        return this.data.status !== 'PENDENTE'
      },

      async set(value) {
        this.isLoadingSwitch = true

        const map = {
          true: 'VISTO',
          false: 'PENDENTE',
        }

        this.data.status = map[value]

        await axiosIns.put(`api/v1/director_channel/director_channel/update/${this.data.id}`, {
          status: this.data.status,
        })
          .catch(error => this.showErrorMessage(error))
          .finally(() => { this.isLoadingSwitch = false })
      },
    },
  },

  created() {
    if (this.data.attachments_id.length > 0) {
      this.files = this.data.attachments_id
      this.isViewingFile = true
    }
  },

  methods: {
    /**
     * Downloads a file given its ID and name.
     * @param {string} fileId - The ID of the file to download.
     * @param {string} fileName - The name of the file to download.
     */
    async downloadFile(fileId, fileName) {
      this.isLoadingFile = fileId

      const body = { id: fileId }

      await axiosIns
        .post('api/v1/director_channel/attachment/view_files', body, { responseType: 'arraybuffer' })
        .then(response => {
          const contentType = response.headers['content-type']
          const file = new File([response.data], fileName, {
            type: contentType,
          })

          saveAs(file)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isLoadingFile = ''
        })
    },
  },
}
</script>

<style scoped>
.chips-container {
    display: flex;
    flex-direction: column;
  }

  .chips-container-content {
    width: 100%;
    height: auto;
    min-height: 60px ;
    display: flex;
    padding: 12px;
    font-size: 10px;
    gap: 6px;

    border-radius: 5px;
    border: 0.0111rem solid #4e4a67;
  }

  .container-chips-text {
    display: relative;
    text-align: center;
    margin-bottom: -10px;
    margin-left: 15px;
    background: #312D4B;
    z-index: 1;
    width: 15%;
  }

  .chips-content {
    margin-right: 0.4444rem;
  }
</style>
