import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"mb-6"},[_c(VCardTitle,{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(" Fale com o Diretor")]),_c(VSpacer)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.isLoading,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"badge mt-2"},[_c(VAlert,{staticStyle:{"font-size":"small"},attrs:{"color":_vm.getStatusColor(item.status),"height":"40px","text":""}},[_vm._v(" "+_vm._s(item.status ? item.status : '')+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at ? _vm.dateFormat(item.created_at) : '')+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"info"},on:{"click":function($event){return _vm.openModal('details', item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])]}}])})],1),_c(VDialog,{attrs:{"width":"500px"},model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}},[_c('Details',{key:_vm.dataDetails.id,attrs:{"data":_vm.dataDetails},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showDetails = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }