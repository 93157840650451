<template>
  <div>
    <v-card class="mb-6">
      <v-card-title class="d-flex justify-content-between">
        <span> Fale com o Diretor</span>

        <v-spacer />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="itemsTable"
        :loading="isLoading"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>

        <template
          v-slot:item.status="{ item }"
        >
          <div class="badge mt-2">
            <v-alert
              :color="getStatusColor(item.status)"
              style="font-size: small;"
              height="40px"
              text
            >
              {{ item.status ? item.status : '' }}
            </v-alert>
          </div>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.created_at ? dateFormat(item.created_at) : '' }}
        </template>

        <template v-slot:item.edit="{ item }">
          <v-icon
            medium
            class="me-2"
            color="info"
            @click="openModal('details', item)"
          >
            {{ icons.mdiEye }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showDetails"
      width="500px"
    >
      <Details
        :key="dataDetails.id"
        :data="dataDetails"
        @updatedTable="updatedTable()"
        @close="showDetails = false"
      ></Details>
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiEye } from '@mdi/js'
import Details from './Details.vue'

export default {
  components: {
    Details,
  },

  mixins: [formatters, messages],

  data() {
    return {
      headers: [
        {
          text: 'ASSUNTO',
          value: 'subject',
          sortable: false,
          align: 'center',
        },
        {
          text: 'CRIADO EM',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DETALHES',
          value: 'edit',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      dataDetails: {},
      isLoading: false,
      showDetails: false,

      icons: {
        mdiEye,
      },
    }
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns.get('api/v1/director_channel/director_channel/index').then(response => {
        const { data } = response.data
        this.itemsTable = data
      })
        .catch(error => this.showErrorMessage(error))
        .finally(() => { this.isLoading = false })
    },

    openModal(type = '', data = {}) {
      const modalMap = {
        details: () => {
          this.showDetails = true
          this.dataDetails = data
        },
      }

      modalMap[type]?.()
    },

    /**
     * Generates the color for a given status.
     *
     * @param {string} status - The status for which to generate the color.
     * @return {string} The color corresponding to the given status. If no color is found, an empty string is returned.
     */
    getStatusColor(status) {
      const colorMap = {
        PENDENTE: '',
        VISTO: 'success',
      }

      return colorMap[status?.toUpperCase()] || ''
    },

    updatedTable() {
      this.getItemsTable()
    },
  },
}
</script>

<style scoped>
.badge {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
